<template>
  <section>
    <v-container>
      <v-row
        :class="fullWidth ? 'pt-5' : 'pt-15'"
        justify="center"
      >
        <v-col :cols="fullWidth ? 12 : 10">
          <v-card
            tile
            elevation="0"
          >
            <v-card-title>
              {{ tableTitle }} Inactivos
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                class="mr-3"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              show-select
              :headers="headers"
              :items="items"
              :search="search"
              loading-text="Cargando... Espera por favor"
              no-data-text="Aún no hay registros"
              no-results-text="No se encontraron coincidencias"
              :footer-props="{
                itemsPerPageText: 'Elementos por página'
              }"
            >
              <template v-slot:[`item.avatar`]="{ item }">
                <v-img
                  v-if="item.groupId === null"
                  :src="require(`../../assets/images/${item.avatar}`)"
                  width="30"
                />
                <v-avatar
                  v-else
                  size="38"
                >
                  <v-img :src="item.avatar" />
                </v-avatar>
              </template>
              <template v-slot:[`item.foto`]="{ item }">
                <v-avatar size="38">
                  <v-img :src="item.foto" />
                </v-avatar>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <kn-menu :items="menuOptions(item)" />
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-if="showBottomAction"
        class="pt-0 mt-n16"
        justify="center"
      >
        <v-col :cols="fullWidth ? 12 : 10">
          <v-btn
            text
            color="primary"
            @click="emitShowAction"
          >
            <span class="text-capitalize mr-2">Ver {{ tableTitle }} activos</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import KnMenu from '../KnMenu.vue'
export default {
  components: { KnMenu },
  props: {
    tableTitle: {
      type: String, 
      default: ""
    },
    newButtonLabel: {
      type: String,
      default: ""
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    showBottomAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: null
    }
  },
  computed: {
    section() {
      if (this.newButtonLabel.includes('alumno')) {
        return 'Alumno'
      } else if (this.newButtonLabel.includes('empleado')) {
        return 'Empleado'
      } else if (this.newButtonLabel.includes('ingreso')) {
        return 'Ingresos'
      } else if (this.newButtonLabel.includes('egreso')) {
        return 'Egresos'
      } else if (this.newButtonLabel.includes('proveedor')) {
        return 'Proveedor'
      } else if (this.newButtonLabel.includes('producto')) {
        return 'Producto'
      } else if (this.newButtonLabel.includes('orden')) {
        return 'Ordenes'
      } else if (this.newButtonLabel.includes('inventario')) {
        return 'Inventario'
      }
      return ''
    },
  },
  methods: {
    menuOptions(item) {
      return [
        {
          title: 'Reactivar',
          action: () => this.emitAction2(item.id),
          section: [
            'Alumno',
            'Empleado',
            'Proveedor',
            // 'Producto'
          ]
        },
      ].filter(option => option.section.some(s => s === this.section))
    },
    goTo(routeName, params) {
      this.$router.push({name: routeName, params: params})
    },
    emitAction(value) {
      this.$emit('action', value)
    },
    emitAction2(value) {
      this.$emit('action2', value)
    },
    emitShowAction() {
      this.$emit('showAction')
    }
  }
}
</script>
